body {
  background: #000000;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
}
  #container {
    max-width: 770px;
    min-width: 220px;
    margin: 0 auto;
    padding: 200px 10px 0px;
  }
    h1 {
      display: none;
    }
    h2 {
      color: #ffffff;
    }
    #form_container {
      color: #ffffff;
      padding-top: 100px;
    }
